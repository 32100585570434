<template>
  <div class="container page home">
    <img alt="Saint Joseph Parish" class="logo" src="../assets/logo.png">
    <p class="total_amount" v-if="donations">{{ getTotal() }}</p>
    <ul class="donations_list">
      <li v-for="item in donations.slice(0, 3)" :key="item.index">
        <p class="donation_name">{{ item.name }}</p>
        <p class="donation_amount">{{ formatCurrency(item.amount) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapState } from 'vuex';

export default {
  name: 'home',
  computed: mapState([
    'donations',
  ]),
  methods: {
    getTotal(){
      let total = 0;
      this.donations.map((donation) => {
        total += donation.amount;
      })
      return total.toLocaleString('en-CA', 
        {style: 'currency', currency: 'CAD'}
      );
    },
    formatCurrency(value){
      return value.toLocaleString('en-CA', {
        style: 'currency',
        currency: 'CAD'
      })
    },
  },
  created(){
    this.$store.dispatch('bindDonations');
  },
  mounted() {
    // this.$store.dispatch('getDonations');
  }
};
</script>

<style lang="scss">
  @import "../styles.scss";

  .home{
    text-align: center;
  }
  .logo{
    max-width: 150px;
    @include breakpoint-min($bp-lg) {
      max-width: 200px;
    }
  }
  .total_amount{
    font-family: 'Josefin Sans', sans-serif;
    font-size: 4rem;
    color: #334b22;
    font-weight: 700;
    @include breakpoint-min($bp-sm) {
      font-size: 8rem;
    }
    @include breakpoint-min($bp-lg) {
      font-size: 10rem;
    }
  }
  .donations_list{
    list-style-type: none;
    margin: 0;
    padding:0;
    &:after{
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      pointer-events: none;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 70%);
      width: 100%;
      height: 40%;
    }
  }
  .donation_name{
    font-size: 1rem;
    margin-bottom: 0px;
    color: #565656;
    @include breakpoint-min($bp-sm) {
      font-size: 1rem;
    }
    @include breakpoint-min($bp-lg) {
      font-size: 2rem;
    }
  }
  .donation_amount {
    font-family: 'Josefin Sans', sans-serif;
    color: #bb4601cf;
    font-weight: bold;
    font-size: 1rem;
    @include breakpoint-min($bp-sm) {
      font-size: 2rem;
    }
    @include breakpoint-min($bp-lg) {
      font-size: 3rem;
    }
  }

</style>
