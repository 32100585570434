import Vue from 'vue';
import { vuexfireMutations, firestoreAction } from 'vuexfire';
import Vuex from 'vuex';
import { db } from './db'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    donations: [],
  },
  mutations: {
    ...vuexfireMutations,
    async ['UPDATE_SUCCESSFULLY'](state, response) {
      let querySnapshot = await db.collection('donations').get();
      state.donations = querySnapshot.docs.map(doc => doc.data());
    }
  },
  actions: {
    bindDonations: firestoreAction(({ bindFirestoreRef }) => {
      bindFirestoreRef(
        'donations', 
        db.collection('donations').orderBy('createdAt', 'desc')
      )
    }),

    async addDonation({commit}, data){
      return await db.collection('donations').add({
        name: data.name,
        amount: parseInt(data.amount),
        createdAt: Date.now()
      });
    },

    async deleteDonation({commit}, data){
      return await db.collection('donations')
        .doc(data.id)
        .delete();
    }

  },
});
